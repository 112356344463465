<template>
  <div>
    <Hero :visible.sync="visible" :show-cutout="false" class="pb-56 pt-28">
      <b-container class="mt-20">
        <b-row>
          <b-col lg="10" offset-lg="1" class="text-center">
            <h1
              class="text-4xl text-md-6xl text-lg-8xl text-white font-weight-black font-realist tracking-tight leading-none mb-md-8 mb-4"
            >
              Get Started.
            </h1>
            <div>
              <p
                class="font-weight-medium text-md-2xl text-xl leading-tight text-white"
              >
                Fill out the form below to have a True360 representative contact
                you.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </Hero>
    <main>
      <b-container class="mt-n32 mb-24">
        <b-row>
          <b-col md="10" offset-md="1" lg="8" offset-lg="2">
            <div class="bg-white rounded shadow p-12">
              <SalesForceForm />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </main>
  </div>
</template>

<script>
import Hero from "@/components/global/Hero";
import SalesForceForm from "../components/SalesForceForm.vue";

export default {
  name: "LearnMore",
  components: {
    Hero,
    SalesForceForm
  },
  data() {
    return {
      visible: true
    };
  }
};
</script>
