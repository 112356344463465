<template>
  <b-form
    @submit="submit"
    action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
    method="POST"
  >
    <input type="hidden" name="oid" value="00Dj0000001rtRh" />
    <input type="hidden" name="retURL" value="https://true360.com/" />
    <input
      type="hidden"
      name="recordType"
      id="recordType"
      value="0125b000000nD4H"
    />
    <!--  ----------------------------------------------------------------------  -->
    <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
    <!--  these lines if you wish to test in debug mode.                          -->
    <!--  <input type="hidden" name="debug" value=1>                              -->
    <!--  <input type="hidden" name="debugEmail"                                  -->
    <!--  value="mcasciano@acvauctions.com">                                      -->
    <!--  ----------------------------------------------------------------------  -->
    <b-form-row>
      <b-col md="6">
        <b-form-group
          label="First Name"
          label-for="first_name"
          label-class="font-weight-bold mb-1 text-uppercase"
          class="mb-6"
          :state="validateState('first_name')"
          maxlength="40"
          invalid-feedback="This field is required"
        >
          <b-form-input
            class="rounded-sm px-4 py-3 h-12 border-gray-00"
            id="first_name"
            maxlength="40"
            name="first_name"
            size="20"
            v-model="$v.form.first_name.$model"
            :state="validateState('first_name')"
            autocomplete="given-name"
            trim
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          label="Last Name"
          label-for="last_name"
          label-class="font-weight-bold mb-1 text-uppercase"
          class="mb-6"
          :state="validateState('last_name')"
          invalid-feedback="This field is required"
        >
          <b-form-input
            class="rounded-sm px-4 py-3 h-12 border-gray-00"
            id="last_name"
            maxlength="80"
            name="last_name"
            size="20"
            v-model="$v.form.last_name.$model"
            :state="validateState('last_name')"
            autocomplete="family-name"
            trim
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          label="Company"
          label-for="company"
          label-class="font-weight-bold mb-1 text-uppercase"
          class="mb-6"
          :state="validateState('company')"
          invalid-feedback="This field is required"
        >
          <b-form-input
            class="rounded-sm px-4 py-3 h-12 border-gray-00"
            id="company"
            maxlength="40"
            name="company"
            size="20"
            v-model="$v.form.company.$model"
            :state="validateState('company')"
            autocomplete="off"
            trim
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          label="Title"
          label-for="title"
          label-class="font-weight-bold mb-1 text-uppercase"
          class="mb-6"
          :state="validateState('title')"
          invalid-feedback="This field is required"
        >
          <b-form-input
            class="rounded-sm px-4 py-3 h-12 border-gray-00"
            id="title"
            maxlength="40"
            name="title"
            size="20"
            v-model="$v.form.title.$model"
            :state="validateState('title')"
            autocomplete="off"
            trim
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          label="Email"
          label-for="email"
          label-class="font-weight-bold mb-1 text-uppercase"
          class="mb-6"
          :state="validateState('email')"
          :invalid-feedback="
            !$v.form.email.email
              ? 'Email must be valid'
              : 'This field is required'
          "
        >
          <b-form-input
            class="rounded-sm px-4 py-3 h-12 border-gray-00"
            id="email"
            maxlength="80"
            name="email"
            size="20"
            type="email"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            autocomplete="email"
            trim
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          label="Phone"
          label-for="phone"
          label-class="font-weight-bold mb-1 text-uppercase"
          class="mb-6"
          :state="validateState('phone')"
          :invalid-feedback="
            !$v.form.phone.phone
              ? 'Phone number must be valid'
              : 'This field is required'
          "
        >
          <b-form-input
            class="rounded-sm px-4 py-3 h-12 border-gray-00"
            id="phone"
            type="tel"
            maxlength="40"
            name="phone"
            size="20"
            v-model="$v.form.phone.$model"
            :state="validateState('phone')"
            autocomplete="tel-national"
            trim
            v-mask="'(###) ###-####'"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col lg="4" offset-lg="4">
        <b-button
          type="submit"
          variant="green-500"
          size="lg"
          :disabled="isSubmitting"
          class="
            font-weight-bold font-realist-normal
            text-xl text-white text-uppercase
            tracking-wider
            w-100
            d-flex
            align-items-center
            justify-content-center
          "
        >
          <template v-if="isSubmitting">
            <b-spinner small class="mr-2 flex-shrink-0"></b-spinner>
            Submitting...
          </template>
          <template v-else>Submit</template>
        </b-button>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { mask } from "vue-the-mask";

const isPhone = value =>
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value);

export default {
  name: "SalesForceForm",
  mixins: [validationMixin],
  directives: { mask },
  watch: {
    visible() {
      this.$emit("update:heroIsVisible", this.visible);
    }
  },
  data() {
    return {
      isSubmitting: false,
      form: {
        email: "",
        first_name: "",
        last_name: "",
        company: "",
        title: "",
        phone: ""
      }
    };
  },
  validations: {
    form: {
      email: { required, email },
      first_name: { required },
      last_name: { required },
      company: { required },
      title: { required },
      phone: { required, phone: isPhone }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return this.$v.form.$dirty && $dirty ? (!$error ? null : false) : null;
    },
    submit(e) {
      this.isSubmitting = true;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        e.preventDefault();
        this.isSubmitting = false;
        return;
      }
    },
    resetForm() {
      this.form.email = "";
      this.form.first_name = "";
      this.form.last_name = "";
      this.form.company = "";
      this.form.title = "";
      this.form.phone = "";
    }
  }
};
</script>
